import logo from "./logo.png";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./business-casual.css";

function App() {
  return (
    <div>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <span class="site-heading-upper text-primary">
            in search of excellence
          </span>
          <p>
            <br />
            This Website is Under Maintenance
          </p>
          <a href="#contact" className="contact-button">
            Contact Us
          </a>
        </header>
      </div>
      <section className="page-section cta" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <div className="cta-inner text-center rounded">
                <h2 className="section-heading mb-5">
                  <span className="section-heading-lower">Contact Us</span>
                </h2>
                <p className="mb-5 site-heading">
                  <em>
                    55/55, City Mill Compound
                    <br />
                    Raipur-Kankaria Road <br />
                    Ahmedabad - 380022
                    <br />
                    Gujarat, INDIA
                  </em>
                </p>
                <div className="row site-heading-lower">
                  <div className="col-sm">
                    <strong className="site-heading"> Office </strong>
                    <br />
                    +91 79 2545 2980 <br />
                    dotprint11@yahoo.co.in
                    <br />
                    <br />
                    <strong className="site-heading"> Mr. Kamal Shah </strong>
                    <br />
                    +91 93761 15531 <br />
                    kamal@dotprint.co.in
                    <br />
                    <br />
                    <strong className="site-heading"> Mr. Mayur Shah </strong>
                    <br />
                    +91 93761 11745 <br />
                    mayur@dotprint.co.in
                    <br />
                    <br />
                    <strong className="site-heading"> Mr. Paritosh Vyas</strong>
                    <br />
                    +91 93761 45455 <br /> paritosh@dotprint.co.in
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
